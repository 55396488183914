// Styles for panels and panes.
// --------------------------------------------------
@import "../base/variables";
@import "../base/mixins";

// IPE styles
div.panels-ipe-handlebar-wrapper li a,
div.panels-ipe-dragtitle span,
div.panels-ipe-newblock a,
span.panels-ipe-draghandle-icon {
  background: transparent none;
  box-shadow: none;
  border-style: none;

  &:focus, &:hover {
    background: transparent none;
    box-shadow: none;
    border-style: none;
  }
}

div.panels-ipe-draghandle,
div.panels-ipe-nodraghandle {
  background: $ipe-color none;
  margin-left: -2px;
  padding-bottom: 0;
	border-radius: 5px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

div.panels-ipe-handlebar-wrapper li a,
div.panels-ipe-dragtitle span,
div.panels-ipe-newblock a,
span.panels-ipe-draghandle-icon {
  background: transparent none;
  box-shadow: none;
  border-style: none;
}

div.panels-ipe-handlebar-wrapper li a span,
div.panels-ipe-newblock a span {
  text-indent: 0;
  color: #fff;
}

div.panels-ipe-handlebar-wrapper li {
  &.edit, &.style, &.delete, &.clone {
    a span {
      background-image: none;
      font-size: 24px;
    }
  }

  &.clone {
    margin: 0 5px 0 0;
  }
}

span.panels-ipe-draghandle-icon {
  margin-top: 5px;

  i {
    font-size: 24px;
    color: #fff;
  }
}

div.panels-ipe-placeholder {
  background-color: #a4a5a5;
  border-style: none;

  i {
    color: $gray;
    font-size: 18px;
  }

  &:hover {
    background-color: $gray;

    i {
      color: #fff;
    }
  }

  li.add-pane {
    margin-left: 0;
  }

  .panels-ipe-newblock {
    box-shadow: none;

    a.add span {
      background-image: none;
      text-align: center;
      margin-top: 5px;
    }
  }
}

.panels-ipe-handlebar-wrapper.panels-ipe-on {
  margin-top: -47px;
}

.panels-ipe-editing div.panels-ipe-portlet-wrapper:hover {
  padding-bottom: 0;
  border: 2px solid $ipe-color;
}

.panels-ipe-editing div.panels-ipe-portlet-wrapper {
  margin-top: 10px;
}

.panels-ipe-portlet-content {
  margin: 0;

  h1, h2, h3, h4, h5 {
    margin-top: 0;
  }
}

div#panels-ipe-control-container {
  position: fixed;
  top: 57px;
  right: 90px;
  left: auto;
  z-index: 1500;
  width: 300px;

  .panels-ipe-form-container form .form-submit {
    padding-left: 10px;
    margin-left: 0;
  }
}

#contextual-tabs,
#panels-ipe-customize-page,
div.panels-ipe-pseudobutton-container a.panels-ipe-change-layout {
  display: none;
}

div.panels-ipe-control {
  .panels-ipe-save, .panels-ipe-cancel {
    background: none;
    color: #fff;
    box-shadow: none;
    font-family: $font-family-sans-serif;
    font-size: 16px;

    &:hover {
      background: none;
    }
  }
}

#panopoly-form-widget-preview {
  padding-bottom: 30px;
}

#edit-field-basic-text-text select {
  margin-top: 30px;
}

// file widget styles
.oa-files-treeview {
  margin-top: 15px;
  position: relative;

  &.oa-files-thumbnail {
    margin-top: -30px;
  }

  a.btn {
    background-color: #3271b3;
    color: #fff;
    border-style: none;
  }

  .oa-files-topbuttons i {
    display: none;
  }

  .oa-file i {
    top: -26px;
  }

  .oa-file-list-hdr {
    padding-right: 110px;
    background-color: #8B8B8B;
    color: #fff;
    height: 30px;

    .oa-file-info {
      font-size: 14px;
      @include vertical-align();
    }

    .name-hdr {
      right: -15px;
    }

    .mod-hdr {
      padding-left: 0;
      margin-right: -95px;
    }

    .upload-hdr {
      padding-left: 66px;
    }

    .size-hdr {
      right: 0;
      left: -2px;
    }
  }

  li.tree-leaf {
    &:nth-child(odd) div {
      background-color: #fff;
    }

    &:nth-child(even) div {
      background-color: #edf0f4;
    }
  }

  .oa-files-leaf:hover {
    .oa-file-info, span, .ajax-progress {
      color: #555;
    }
  }

  .custom-file-thumb-img, .custom-file-thumb-icon {
    border-color: $gray-light;
  }

  .custom-file-thumb-desc {
    color: #000;
  }
}

// responsive iframes

.intrinsic-container {
  position: relative;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* 16x9 Aspect Ratio */
.intrinsic-container-16x9 {
  padding-bottom: 56.25%;
}

/* 4x3 Aspect Ratio */
.intrinsic-container-4x3 {
  padding-bottom: 75%;
}
