// Colorbox styles
// --------------------------------------------------
@import "../base/variables";
@import "../base/mixins";

#colorbox {
  #cboxContent {
    border-radius: 0px;
  }

  #cboxClose {
    top: -1px;
    right: -1px;
    color: transparent;
    background-color: transparent;
    height: 25px;
    width: 25px;

    &:before {
      padding: 2px 5px 2px 5px;
      background-color: $gray;
      color: #fff;
      font-family: FontAwesome;
      content: "\f00d";
    }

    &.cbox-close-plain {
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
    }
  }

  #cboxContent,
  .cboxPhoto {
    border-radius: 0;
  }

  &.pdf-viewer {
    top: 5px;

    #cboxClose:before {
      background-color: transparent;
      color: #D7D7D7;
      font-size: 18px;
    }
  }
}

#cboxOverlay {
  @include modal-bg();
}
