// Bootstrap styles
// --------------------------------------------------

@import "../base/variables";

.progress {
  border-radius: 0;
}

.progress-bar-success {
  background-color: $brand-success;
}
