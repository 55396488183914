// Styles for Forms
// --------------------------------------------------

.compact-form-header {
  @include loginhdr;
  text-align: center;
}

.form-actions {
  background: none !important;
}

form {
  .desc-highlight {
    color: #d04437;
  }

  .supascript {
    font-size: 0.8em;
    vertical-align:top;
  }
}

#autosave-status {
  background-color: #555;
  padding: 10px 0;
  height: auto;
}

.chosen-fieldset-wrapper .chosen-container {
  width: 100% !important;
}

.webform-component-number {
  &.webform-container-inline label {
    display: inline-block;
  }

  label {
    display: block;
  }

  .form-control {
    width: auto;
  }
}

.webform-container-inline .form-control {
  width: auto;
  display: inline-block;
}
