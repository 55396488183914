#modalContent {
  h5 {
    display: none;
  }

  ul {
    margin: 10px 0 0;
  }

  .panels-section-column-categories {
    .btn {
      border-width: 0 !important;
    }
  }

  .panels-categories-box {
    .btn {
      font-size: 20px;
      font-weight: 400;
      font-style: normal;
      color: #565656;
      font-family: 'Roboto', sans-serif;

      &:hover, &:focus, &.active {
        background: none;
        box-shadow: none;
        color: #5d9dd2 !important;
        border-width: 0;
        outline: none;
      }
    }
  }

  .panels-root-content-box {
    display: none;
  }

  .button {
    background-color: transparent;
    color: #3271b3 !important;
    padding: 0;
    font-size: 24px;
    border-width: 0;
  }

  .btn-success {
    background-color: #3271b3;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;

    i {
      display: none;
    }
  }

  .help-block {
    margin-top: 0;
  }
}

#modal-content {
  .panels-add-content-modal {
    .panopoly-magic-preview-link {
      border-bottom-width: 0;
      font-size: 24px;
    }

    .widget-preview {
      margin: 20px 0;
    }

    form {
      .form-item {
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}
