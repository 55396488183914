.oa-navbar .panel-panel .panel-pane.pane-superfish-1 ul li {
  &:hover,
  &.sfHover {
    > a {
      background-color: #c1c1c1;
      color: #fff;

      i {
        color: #fff;
      }
    }
  }
}