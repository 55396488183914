form {
  &#add-message-edit {
    width: 100%;

    .form-item-dropdown label {
      line-height: 34px;
      margin-right: 10px;
    }
  }
}
