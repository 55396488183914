// Ui dialog styles
// --------------------------------------------------

.ui-dialog {
  border-radius: 0;
  box-shadow: none;
  padding: 0;

  .ui-dialog-titlebar {
    background-color: #E2E2E2;
    border-style: none;
    border-radius: 0;
  }

  .ui-dialog-title {
    color: #000;
  }

  .ui-dialog-titlebar-close {
    outline: none;

    &:before {
      font-family: FontAwesome;
      content: "\f00d";
    }
  }

  .ui-state-default .ui-icon {
    background-image: none;
  }
}

.ui-widget-overlay {
  background-color: #57585A;
  background-image: none;
  opacity: 0.5;
}

.ui-front {
  z-index: 10001 !important;
}
