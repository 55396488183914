/*
 * A partial implementation of the Ruby list functions from Compass:
 * https://github.com/Compass/compass/blob/stable/lib/compass/sass_extensions/functions/lists.rb
 */
/*
 * A partial implementation of the Ruby constants functions from Compass:
 * https://github.com/Compass/compass/blob/stable/lib/compass/sass_extensions/functions/constants.rb
 */
/*
 * A partial implementation of the Ruby display functions from Compass:
 * https://github.com/Compass/compass/blob/stable/core/lib/compass/core/sass_extensions/functions/display.rb
 */
#auth-footer {
  text-align: center;
  margin: 20px 0;
}

#auth-footer img {
  margin-bottom: 10px;
}

body #footer {
  background-color: transparent;
  text-align: center;
  border-top-style: none;
}

@media (min-width: 768px) {
  body #footer .footer-line {
    display: inline;
  }
  body #footer .footer-bullet {
    margin: 0 1px 0 3px;
  }
}

@media (max-width: 767px) {
  #footer .footer-bullet {
    display: none;
  }
}

body {
  font-family: Roboto, Arial, sans-serif;
}

body h2 {
  font-size: 18px;
}

body.not-logged-in {
  background-image: url(https://s3-us-west-2.amazonaws.com/gmail-bucket/Impetus+InSite+Platform/login-screen-background.jpg);
  background-repeat: repeat;
  background-color: #e5e7e6;
  background-position: center;
  background-size: auto;
}

body.not-logged-in #main-wrapper {
  background-color: transparent;
}

body.not-logged-in #main-wrapper #main {
  margin: 0 0;
  background-color: transparent;
}

@media (max-width: 1199px) {
  body #main {
    margin-left: 5px;
    margin-right: 5px;
  }
}

body .ui-widget-overlay {
  position: fixed;
  opacity: 0.65 !important;
  background-color: #000 !important;
}

body.page-oa-files-upload {
  background-color: #fff;
}

@media (max-width: 1024px) {
  body .radix-layouts-sidebar {
    display: none;
  }
}

body .radix-layouts-sidebar .pane-title {
  margin-top: 0;
}

body .radix-layouts-sidebar .oa-pane {
  border-style: none;
}

body .radix-layouts-content .panel-panel-inner div:first-child h3 {
  margin-top: 0;
}

body .pane-og-menu-single-menu.panel-pane {
  padding-bottom: 0;
}

body .pane-og-menu-single-menu.panel-pane h2 {
  width: 100%;
  padding: 10px 15px;
  line-height: 32px;
  font-size: 18px;
  margin-bottom: 0;
}

body .pane-og-menu-single-menu.panel-pane h2 a {
  color: #fff;
}

body .pane-og-menu-single-menu.panel-pane .pane-content {
  padding: 0;
}

body .pane-og-menu-single-menu.panel-pane .menu li.dhtml-menu.dhtml-folder.expanded .dhtml-menu-icon,
body .pane-og-menu-single-menu.panel-pane .menu li.dhtml-menu.dhtml-folder.collapsed .dhtml-menu-icon, body .pane-og-menu-single-menu.panel-pane .menu li.dhtml-menu.dhtml-folder.expanded::before, body .pane-og-menu-single-menu.panel-pane .menu li.dhtml-menu.dhtml-folder.collapsed::before {
  content: '';
  background-image: none;
}

body .pane-og-menu-single-menu.panel-pane .menu li.dhtml-menu.dhtml-folder a.active {
  width: 100%;
  font-weight: bold;
}

body .pane-og-menu-single-menu.panel-pane .menu li.dhtml-menu i {
  float: right;
  font-size: 30px;
  padding: 5px 15px 5px 10px;
}

body .pane-og-menu-single-menu.panel-pane .menu li.dhtml-menu i:hover {
  cursor: pointer;
}

body .pane-og-menu-single-menu.panel-pane .menu li.dhtml-menu i.completion-indicator {
  font-size: 25px;
  margin-top: 3px;
  padding-right: 0;
}

body .pane-og-menu-single-menu.panel-pane .menu li.dhtml-menu i.completion-indicator.incomplete {
  color: #d3d3d3;
}

body .pane-og-menu-single-menu.panel-pane .menu li.dhtml-menu i.completion-indicator.complete {
  color: #1faa5e;
}

body .pane-og-menu-single-menu.panel-pane .menu li.dhtml-menu.leaf i.completion-indicator {
  margin-right: 44px;
}

body .pane-og-menu-single-menu.panel-pane ul.menu > li > ul.menu {
  margin-left: 0;
}

body .pane-og-menu-single-menu.panel-pane ul.menu > li > ul.menu a {
  padding-left: 30px;
}

body .pane-og-menu-single-menu.panel-pane ul.menu > li > ul.menu ul.menu a {
  padding-left: 50px;
}

body .pane-og-menu-single-menu.panel-pane li {
  background-color: #fff;
  border-bottom: 2px solid;
}

body .pane-og-menu-single-menu.panel-pane li:first-child {
  border-top: 2px solid;
}

body .pane-og-menu-single-menu.panel-pane li:last-child {
  border-bottom-style: none;
}

body .pane-og-menu-single-menu.panel-pane li a {
  color: inherit;
  display: block;
  padding: 10px 15px;
  font-size: 18px;
}

body .pane-og-menu-single-menu.panel-pane li li a {
  font-size: 16px;
}

body .pane-og-menu-single-menu.panel-pane .menu-comments-new {
  margin-left: 10px;
  background-color: #c9243f;
  color: #ffffff;
  padding: 1px 7px 0px 7px;
  font-size: 12px;
  display: inline-block;
  border-radius: 3px;
}

body .ctools-collapsible-container .ctools-toggle, body .ctools-collapsible-container .ctools-toggle-collapsed {
  background-image: none;
}

body table.sticky-header {
  z-index: 999;
}

.oa-site-layout-default .oa-layout-sidebar1 {
  display: none;
}

.ajax-progress-throbber,
.ajax-progress .throbber {
  background-image: none;
}

.ajax-progress-throbber:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f110";
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

.progress {
  border-radius: 0;
}

.progress-bar-success {
  background-color: #1faa5e;
}

#colorbox #cboxContent {
  border-radius: 0px;
}

#colorbox #cboxClose {
  top: -1px;
  right: -1px;
  color: transparent;
  background-color: transparent;
  height: 25px;
  width: 25px;
}

#colorbox #cboxClose:before {
  padding: 2px 5px 2px 5px;
  background-color: #565656;
  color: #fff;
  font-family: FontAwesome;
  content: "\f00d";
}

#colorbox #cboxClose.cbox-close-plain {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

#colorbox #cboxContent,
#colorbox .cboxPhoto {
  border-radius: 0;
}

#colorbox.pdf-viewer {
  top: 5px;
}

#colorbox.pdf-viewer #cboxClose:before {
  background-color: transparent;
  color: #D7D7D7;
  font-size: 18px;
}

#cboxOverlay {
  opacity: 0.65 !important;
  background-color: #000 !important;
}

div.comment-form {
  margin-right: 10px;
}

form.comment-form {
  margin-bottom: 60px;
}

form.comment-form .form-actions {
  margin: 65px 4px 0 0;
}

form.comment-form .form-actions .ajax-progress-throbber {
  margin-top: 5px;
  float: right;
  margin-right: 10px;
}

form.comment-form .form-actions input[id^=cancel-ajax-comments-reply-form] {
  clear: none;
}

form.comment-form label[for^=edit-comment-body] {
  display: none;
}

form.comment-form div[id^=edit-comment-body] {
  margin-left: 28%;
}

.comment-form-row.SM div[id^=edit-comment-body] {
  margin-left: 83px;
}

.indented > form.comment-form {
  padding-left: 75px;
}

@media (max-width: 767px) {
  .indented > form.comment-form {
    padding-left: 5px;
  }
}

.panel-pane.pane-node-comment-form {
  background-color: #fff;
  padding: 20px 20px 5px 20px;
}

.panel-pane.pane-node-comment-form h2 {
  display: none;
}

.comment-user-img {
  float: left;
}

.comment-user-img img {
  height: 55px;
  width: 55px;
  max-height: none;
  max-width: none;
  display: block;
  margin-top: 7px;
}

#comments .oa-comment.well {
  background-image: none;
  background-color: #fff;
  border-style: none;
}

#comments .oa-comment .links {
  float: left;
  font-size: 14px;
  text-transform: capitalize;
  margin-left: -5px;
  color: #336EAA;
}

#comments .oa-comment .links a {
  color: #336EAA;
  font-size: 14px;
}

#comments .oa-comment .links .ajax-progress-throbber {
  margin-right: 3px;
}

@media (max-width: 767px) {
  #comments .oa-comment .links a {
    font-size: 12px;
  }
}

#comments .oa-comment .user-info {
  margin-bottom: 10px;
}

#comments .oa-comment .user-info a, #comments .oa-comment .user-info .oa-date {
  font-size: 16px;
}

#comments .oa-comment .user-info a {
  color: #2C6FB6;
}

#comments .oa-comment .user-info .oa-date {
  color: #336EAA;
  padding-left: 0;
}

#comments .oa-comment .user-picture img {
  height: 55px;
  width: 55px;
  max-height: none;
  max-width: none;
}

#comments .oa-comment .oa-date {
  display: block;
  float: none !important;
}

#comments .oa-comment .comment-like i {
  margin-right: 3px;
}

#comments .oa-comment .comment-like-bar {
  display: none;
}

#comments .oa-comment .like-summary {
  text-transform: none;
  font-size: 13px;
}

#comments .oa-comment form.confirmation .ajax-progress-throbber {
  margin-right: 6px;
}

@media (max-width: 767px) {
  #comments .oa-comment .like-summary {
    font-size: 12px;
    line-height: 120%;
    margin-left: 5px;
    display: block;
  }
}

#comments, .pane-node-comments {
  background-color: #fff;
  margin-top: 0;
  padding: 0;
}

#comments h2, .pane-node-comments h2 {
  display: none;
}

#comments .comment-reply-post-button {
  width: 200px;
}

.comment-unpublished {
  background-color: #ffffff;
}

.pane-content > .oa-comment {
  margin-top: 20px;
}

.oa-comment ~ .indented {
  margin: 20px 0 0 0;
}

.oa-comment ~ .indented > .oa-comment {
  padding-left: 5px;
}

#comments.SM .comment-like-bar {
  display: inline;
}

#comments.SM .oa-comment ~ .indented > .oa-comment {
  padding-left: 75px;
}

#comments.MD .oa-date {
  float: right !important;
  padding-left: 5px;
}

#comments.MD .oa-comment ~ .indented .indented > .oa-comment {
  padding-left: 150px;
}

.oa-comment-reply-body {
  font-size: 14px;
  padding: 8px 5px 0 5px;
}

@media (max-width: 767px) {
  .oa-comment-reply-body .label {
    display: inline-block;
    margin-top: 5px;
  }
}

#sliding-popup .popup-content #popup-buttons {
  margin-left: 0px;
}

@media (min-width: 767px) {
  #sliding-popup .popup-content #popup-buttons {
    float: left;
    margin-left: 25px;
  }
}

.ui-dialog {
  border-radius: 0;
  box-shadow: none;
  padding: 0;
}

.ui-dialog .ui-dialog-titlebar {
  background-color: #E2E2E2;
  border-style: none;
  border-radius: 0;
}

.ui-dialog .ui-dialog-title {
  color: #000;
}

.ui-dialog .ui-dialog-titlebar-close {
  outline: none;
}

.ui-dialog .ui-dialog-titlebar-close:before {
  font-family: FontAwesome;
  content: "\f00d";
}

.ui-dialog .ui-state-default .ui-icon {
  background-image: none;
}

.ui-widget-overlay {
  background-color: #57585A;
  background-image: none;
  opacity: 0.5;
}

.ui-front {
  z-index: 10001 !important;
}

.compact-form-header {
  color: #fff;
  text-transform: none;
  font-weight: bold;
  font-size: 28px;
  padding: 30px 0;
  letter-spacing: 3px;
  text-align: center;
}

.form-actions {
  background: none !important;
}

form .desc-highlight {
  color: #d04437;
}

form .supascript {
  font-size: 0.8em;
  vertical-align: top;
}

#autosave-status {
  background-color: #555;
  padding: 10px 0;
  height: auto;
}

.chosen-fieldset-wrapper .chosen-container {
  width: 100% !important;
}

.webform-component-number.webform-container-inline label {
  display: inline-block;
}

.webform-component-number label {
  display: block;
}

.webform-component-number .form-control {
  width: auto;
}

.webform-container-inline .form-control {
  width: auto;
  display: inline-block;
}

@media (max-width: 767px) {
  header.col-md-12 {
    padding: 0 0;
  }
  header .navbar-fixed-top.oa-fullwidth {
    position: static;
  }
  header div.oa-hidden {
    display: none;
  }
}

.oa-navbar .oa-site-banner-img {
  margin-left: 5px;
}

@media (max-width: 1024px) {
  .oa-navbar div[class*="-mobile-menu"] {
    display: none;
  }
}

.oa-navbar #breadcrumb-hint-img {
  max-width: none;
  height: 25px;
  position: absolute;
  top: -30px;
  left: 60px;
}

.oa-navbar #breadcrumb-hint-text {
  width: 700px;
  position: absolute;
  top: -29px;
  left: 110px;
  font-family: 'Roboto Slab', serif;
  font-size: 18px;
  font-weight: bold;
}

.oa-navbar #oa_breadcrumb .dropdown-toggle {
  display: none;
}

.oa-navbar #oa_breadcrumb .dropdown-menu {
  display: none;
}

.oa-navbar #oa_breadcrumb > li {
  position: relative;
}

.oa-navbar #oa_breadcrumb > li:first-child a {
  padding-left: 0;
  position: static;
  top: 0;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.oa-navbar #oa_breadcrumb > li:after {
  content: '' !important;
}

.oa-navbar #oa_breadcrumb > li a {
  padding-top: 0;
  padding-left: 30px;
  text-shadow: none !important;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.oa-navbar #oa_breadcrumb .oa-breadcrumb-arrow {
  position: absolute;
  right: -10px;
  top: 0;
  width: 0;
  height: 0;
  z-index: 1000;
  border-top: 21px solid transparent;
  border-left: 10px solid #0da6f7;
  border-bottom: 21px solid transparent;
}

.oa-navbar .panel-panel .panel-pane {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.oa-navbar .panel-panel .panel-pane.pane-superfish-1 {
  display: block;
}

@media (min-width: 1025px) {
  .oa-navbar .panel-panel .panel-pane.pane-superfish-1 {
    top: -99999em;
    background-color: #fff;
    position: absolute;
  }
  .oa-navbar .panel-panel .panel-pane.pane-superfish-1 .fa {
    font-size: 24px;
    float: right;
    margin-top: 5px;
    color: #565656;
  }
  .oa-navbar .panel-panel .panel-pane.pane-superfish-1 .sf-vertical {
    width: auto;
  }
  .oa-navbar .panel-panel .panel-pane.pane-superfish-1 .sf-vertical > li {
    width: auto;
  }
  .oa-navbar .panel-panel .panel-pane.pane-superfish-1 ul li a {
    color: #565656;
    border-radius: 0;
    line-height: 38px;
    padding: 3px 20px;
    background-color: #fff;
  }
  .oa-navbar .panel-panel .panel-pane.pane-superfish-1 ul li a:hover, .oa-navbar .panel-panel .panel-pane.pane-superfish-1 ul li a:focus {
    background-color: #C1C1C1 !important;
    color: #fff;
    text-decoration: none;
  }
  .oa-navbar .panel-panel .panel-pane.pane-superfish-1 ul li a:hover i, .oa-navbar .panel-panel .panel-pane.pane-superfish-1 ul li a:focus i {
    color: #fff;
  }
  .oa-navbar .panel-panel .panel-pane.pane-superfish-1 ul li:hover > a, .oa-navbar .panel-panel .panel-pane.pane-superfish-1 ul li.sfHover > a {
    background-color: #c1c1c1;
    color: #fff;
  }
  .oa-navbar .panel-panel .panel-pane.pane-superfish-1 ul li:hover > a i, .oa-navbar .panel-panel .panel-pane.pane-superfish-1 ul li.sfHover > a i {
    color: #fff;
  }
  .oa-navbar .panel-panel .panel-pane.pane-superfish-1 ul div.sf-scroll-up, .oa-navbar .panel-panel .panel-pane.pane-superfish-1 ul div.sf-scroll-dn {
    float: left;
    width: 100%;
    background-color: #fff;
    padding: 0.5em 0px;
  }
  .oa-navbar .panel-panel .panel-pane.pane-superfish-1 ul div.sf-scroll-up:hover, .oa-navbar .panel-panel .panel-pane.pane-superfish-1 ul div.sf-scroll-dn:hover {
    background-color: #c1c1c1;
  }
  .oa-navbar .panel-panel .panel-pane.pane-superfish-1 ul .sf-uparrow,
  .oa-navbar .panel-panel .panel-pane.pane-superfish-1 ul .sf-dnarrow,
  .oa-navbar .panel-panel .panel-pane.pane-superfish-1 ul .sf-uparrow-disabled,
  .oa-navbar .panel-panel .panel-pane.pane-superfish-1 ul .sf-dnarrow-disabled {
    margin: 0 auto;
    width: 10px;
    height: 10px;
    text-indent: -999em;
    overflow: hidden;
    background: url("../images/updn-arrows-superscroll.png") no-repeat 0 0;
  }
  .oa-navbar .panel-panel .panel-pane.pane-superfish-1 ul .sf-dnarrow {
    background-position: 0 -100px;
  }
  .oa-navbar .panel-panel .panel-pane.pane-superfish-1 ul .sf-uparrow {
    background-position: 0 0;
  }
  .oa-navbar .panel-panel .panel-pane.pane-superfish-1 ul .sf-dnarrow-disabled {
    background-position: -10px -100px;
  }
  .oa-navbar .panel-panel .panel-pane.pane-superfish-1 ul .sf-uparrow-disabled {
    background-position: -10px 0;
  }
}

.oa-navbar .panel-panel .panel-pane .dl-menuwrapper .dl-menu {
  height: 1000px;
  width: 275px;
  top: -5px;
  left: -275px;
  background-color: #565656;
  opacity: 1;
  -webkit-transform: none;
  transform: none;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  overflow-x: hidden;
}

.oa-navbar .panel-panel .panel-pane .dl-menuwrapper .dl-menu:after {
  content: "";
  display: block;
  width: 20px;
  height: 120%;
  position: absolute;
  left: 100%;
  top: -10%;
  z-index: 99;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}

.oa-navbar .panel-panel .panel-pane .dl-menuwrapper .dl-menu > a {
  display: block;
  width: 100%;
  text-align: center;
  height: 71px;
}

@media (min-width: 1024px) {
  .oa-navbar .panel-panel .panel-pane .dl-menuwrapper .dl-menu > a {
    height: 59px;
  }
}

.oa-navbar .panel-panel .panel-pane .dl-menuwrapper .dl-menu > a img {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.oa-navbar .panel-panel .panel-pane .dl-menuwrapper .dl-menu.dl-menu-toggle {
  transition: none;
}

.oa-navbar .panel-panel .panel-pane .dl-menuwrapper .dl-menu.dl-animate-in-1 {
  animation-duration: 0.1s;
  animation-name: ImpMenuAnimIn;
  animation-timing-function: linear;
}

.oa-navbar .panel-panel .panel-pane .dl-menuwrapper .dl-menu.dl-animate-out-1 {
  animation-duration: 0.1s;
  animation-name: ImpMenuAnimOut;
  animation-timing-function: linear;
}

.oa-navbar .panel-panel .panel-pane .dl-menuwrapper .dl-curpage {
  background-color: #79777A;
}

.oa-navbar .panel-panel .panel-pane .dl-menuwrapper > .dl-submenu {
  width: 275px;
  height: 100%;
  top: 61px;
}

.oa-navbar .panel-panel .panel-pane .dl-menuwrapper > .dl-submenu.dl-animate-in-1 {
  animation-duration: 0;
  animation-name: ImpSubMenuAnimIn;
  animation-timing-function: linear;
}

.oa-navbar .panel-panel .panel-pane .dl-menuwrapper > .dl-submenu.dl-animate-out-1 {
  animation-duration: 0.1s;
  animation-name: ImpSubMenuAnimOut;
  animation-timing-function: linear;
}

.oa-navbar .panel-panel .panel-pane .dl-menuwrapper .dl-submenu {
  position: absolute;
  width: 275px;
}

.oa-navbar .panel-panel .panel-pane .dl-menuwrapper ul {
  background-color: #565656;
}

.oa-navbar .panel-panel .panel-pane .dl-menuwrapper li a {
  font-size: 12px;
}

.oa-navbar .panel-panel .panel-pane .dl-menuwrapper li .dl-back a {
  text-transform: capitalize;
}

.oa-navbar .panel-panel .panel-pane .dl-menuwrapper li.dl-back:after,
.oa-navbar .panel-panel .panel-pane .dl-menuwrapper li > a:not(:only-child):after {
  font-family: FontAwesome;
  content: "\f105";
  color: #fff;
  font-size: 18px;
}

.oa-navbar .panel-panel .panel-pane .dl-menuwrapper button:hover, .oa-navbar .panel-panel .panel-pane .dl-menuwrapper button.dl-active {
  background: transparent;
}

@media (max-width: 1024px) {
  .oa-navbar #toolbar-touchpoint-button,
  .oa-navbar .toolbar-button {
    display: none;
  }
}

.oa-navbar #toolbar-menu-button,
.oa-navbar #toolbar-touchpoint-button,
.oa-navbar .toolbar-button {
  height: 39px;
}

.oa-navbar #toolbar-menu-button i,
.oa-navbar #toolbar-touchpoint-button i,
.oa-navbar .toolbar-button i {
  font-size: 25px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.oa-navbar #toolbar-touchpoint-button i {
  color: #fff;
  font-size: 27px;
}

.oa-navbar div.pane-oa-toolbar-oa-user-badge.user-mobile-menu {
  display: block;
}

.oa-navbar .row:first-child {
  background-color: #fff;
}

.dropdown-menu {
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding-top: 0;
  padding-bottom: 0;
}

.dropdown-menu ul li a {
  line-height: 38px;
}

#navbar-administration {
  font-family: Roboto, Arial, sans-serif;
  color: #565656;
}

#navbar-administration h3 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #565656;
  padding: 10px 15px;
}

#navbar-administration h3.element-invisible {
  clip: auto;
  height: auto;
  overflow: visible;
  position: static !important;
}

#navbar-administration h3 i {
  font-size: 35px;
  display: block;
  float: left;
  margin-top: -10px;
  margin-right: 10px;
}

#navbar-administration h3 i:hover {
  cursor: pointer;
}

#navbar-administration a {
  font-size: 12px;
  margin-left: 4em;
  padding-left: 0;
}

#navbar-administration a.navbar-icon::before {
  background-image: none;
}

#navbar-administration .navbar-box i {
  float: left;
  display: block;
  font-size: 30px;
  margin-top: 4px;
  margin-left: 20px;
}

#navbar-administration .navbar-box i:hover {
  cursor: pointer;
}

#navbar-administration .navbar-handle {
  display: none;
}

#navbar-administration .navbar-tray-vertical {
  z-index: 1000;
}

#navbar-administration .navbar-tray-vertical.active > .navbar-lining {
  top: 3px;
  border-right: none;
}

#navbar-administration .menu li {
  border-top: none;
}

#navbar-administration .menu li:last-child {
  border-bottom: none;
}

#navbar-administration .menu ul ul {
  border-top: none;
  border-bottom: none;
  background-color: #fff;
}

#navbar-administration .menu a {
  color: #565656;
}

#navbar-administration .menu a:hover {
  color: #565656;
}

#navbar-administration .menu .menu a {
  color: #565656;
}

#oa-user-badge {
  margin-bottom: 10px;
}

#oa-user-badge .user-badge {
  font-weight: bold;
}

#oa-user-badge .user-badge img {
  margin: 0 7px 0 0;
  display: inline-block;
  border-radius: 50%;
}

#oa-user-badge .user-badge span {
  display: inline;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
}

#oa-user-badge .user-badge i {
  margin-top: -17px;
  font-size: 28px;
}

#oa-user-badge .btn {
  padding-left: 5px;
  padding-right: 0;
}

#oa-user-badge .btn-group.open .dropdown-toggle {
  box-shadow: none;
}

#oa-user-badge .dropdown-menu {
  min-width: 170px;
  top: 43px;
  z-index: 1005;
}

#oa-user-badge .dropdown-menu ul li a:hover, #oa-user-badge .dropdown-menu ul li a:focus {
  background-color: #C1C1C1;
  background-image: none;
  color: #fff;
}

@-webkit-keyframes ImpMenuAnimIn {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ImpMenuAnimIn {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes ImpSubMenuAnimIn {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ImpSubMenuAnimIn {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes ImpMenuAnimOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ImpMenuAnimOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes ImpSubMenuAnimOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes ImpSubMenuAnimOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

#modalBackdrop {
  background-color: #000 !important;
  opacity: 0.65 !important;
}

#modalContent .ctools-modal-content {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-shadow: none !important;
  border-radius: 0;
}

#modalContent .ctools-modal-content.modal-forms-modal-content {
  font-family: Roboto, Arial, sans-serif;
}

#modalContent .ctools-modal-content form > div > .form-item {
  border-bottom-style: none;
}

#modalContent .ctools-modal-content .popups-container {
  border-radius: 0;
}

#modalContent .ctools-modal-content .popups-container .modal-header, #modalContent .ctools-modal-content .popups-container .modal-content {
  border-radius: 0;
}

#modalContent .ctools-modal-content .popups-container .modal-header {
  background-color: #E2E2E2;
  background-image: none;
  height: 40px;
}

#modalContent .ctools-modal-content .popups-container .modal-header .modal-title {
  color: #000;
  display: block;
  float: left;
  font-size: 16px;
  margin-left: 10px;
}

#modalContent .ctools-modal-content .popups-container .modal-header span.popups-close.close {
  display: block;
  background: none;
  text-indent: 12px;
  color: #E2E2E2;
  opacity: 1;
}

#modalContent .ctools-modal-content .popups-container .modal-header span.popups-close.close:before {
  font-size: 16px;
  opacity: 1;
  color: #565656;
  font-family: FontAwesome;
  content: "\f00d";
}

#modalContent .ctools-modal-content .popups-container .modal-content {
  border-style: none;
  box-shadow: none !important;
}

#modalContent .panels-categories-description {
  height: 100%;
}

#modalContent .panels-categories-description .inner {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#modalContent #filter_widgets_text {
  height: 50px;
  font-size: 20px;
  margin: 20px 0 30px 0;
}

#modalContent #edit-buttons {
  padding-top: 2em;
  border-top: 1px solid #e5e5e5;
  position: fixed;
  bottom: 0;
  right: 0;
}

#modalContent .ctools-auto-submit-full-form .form-actions,
#modalContent #edit-buttons {
  max-width: 100%;
  width: 100%;
  padding-right: 30px;
}

#modalContent .ctools-auto-submit-full-form .form-actions #edit-cancel-button,
#modalContent #edit-buttons #edit-cancel-button {
  float: left;
  margin-left: 40px;
}

#modalContent .ctools-auto-submit-full-form .form-actions #edit-return,
#modalContent #edit-buttons #edit-return {
  float: right;
}

#modalContent .ctools-auto-submit-full-form .form-actions .ajax-progress-throbber,
#modalContent #edit-buttons .ajax-progress-throbber {
  margin-right: 10px;
}

#modalContent fieldset.widget-preview {
  margin-top: 0;
}

#modalContent fieldset.widget-preview .panel-body {
  height: 68vh;
  max-height: 68vh;
}

#modalContent .cke_contents {
  height: 39vh;
  max-height: 39vh;
}

#modalContent #edit-field-basic-text-text textarea {
  height: 46vh;
  max-height: 46vh;
}

#modalContent #edit-field-basic-text-text .markItUp textarea {
  height: 43vh;
  max-height: 43vh;
}

#modalContent .ctools-auto-submit-full-form {
  float: left;
  width: 48%;
}

#modalContent .ctools-auto-submit-full-form fieldset {
  width: 100%;
}

div.panels-ipe-handlebar-wrapper li a,
div.panels-ipe-dragtitle span,
div.panels-ipe-newblock a,
span.panels-ipe-draghandle-icon {
  background: transparent none;
  box-shadow: none;
  border-style: none;
}

div.panels-ipe-handlebar-wrapper li a:focus, div.panels-ipe-handlebar-wrapper li a:hover,
div.panels-ipe-dragtitle span:focus,
div.panels-ipe-dragtitle span:hover,
div.panels-ipe-newblock a:focus,
div.panels-ipe-newblock a:hover,
span.panels-ipe-draghandle-icon:focus,
span.panels-ipe-draghandle-icon:hover {
  background: transparent none;
  box-shadow: none;
  border-style: none;
}

div.panels-ipe-draghandle,
div.panels-ipe-nodraghandle {
  background: #3271b3 none;
  margin-left: -2px;
  padding-bottom: 0;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

div.panels-ipe-handlebar-wrapper li a,
div.panels-ipe-dragtitle span,
div.panels-ipe-newblock a,
span.panels-ipe-draghandle-icon {
  background: transparent none;
  box-shadow: none;
  border-style: none;
}

div.panels-ipe-handlebar-wrapper li a span,
div.panels-ipe-newblock a span {
  text-indent: 0;
  color: #fff;
}

div.panels-ipe-handlebar-wrapper li.edit a span, div.panels-ipe-handlebar-wrapper li.style a span, div.panels-ipe-handlebar-wrapper li.delete a span, div.panels-ipe-handlebar-wrapper li.clone a span {
  background-image: none;
  font-size: 24px;
}

div.panels-ipe-handlebar-wrapper li.clone {
  margin: 0 5px 0 0;
}

span.panels-ipe-draghandle-icon {
  margin-top: 5px;
}

span.panels-ipe-draghandle-icon i {
  font-size: 24px;
  color: #fff;
}

div.panels-ipe-placeholder {
  background-color: #a4a5a5;
  border-style: none;
}

div.panels-ipe-placeholder i {
  color: #565656;
  font-size: 18px;
}

div.panels-ipe-placeholder:hover {
  background-color: #565656;
}

div.panels-ipe-placeholder:hover i {
  color: #fff;
}

div.panels-ipe-placeholder li.add-pane {
  margin-left: 0;
}

div.panels-ipe-placeholder .panels-ipe-newblock {
  box-shadow: none;
}

div.panels-ipe-placeholder .panels-ipe-newblock a.add span {
  background-image: none;
  text-align: center;
  margin-top: 5px;
}

.panels-ipe-handlebar-wrapper.panels-ipe-on {
  margin-top: -47px;
}

.panels-ipe-editing div.panels-ipe-portlet-wrapper:hover {
  padding-bottom: 0;
  border: 2px solid #3271b3;
}

.panels-ipe-editing div.panels-ipe-portlet-wrapper {
  margin-top: 10px;
}

.panels-ipe-portlet-content {
  margin: 0;
}

.panels-ipe-portlet-content h1, .panels-ipe-portlet-content h2, .panels-ipe-portlet-content h3, .panels-ipe-portlet-content h4, .panels-ipe-portlet-content h5 {
  margin-top: 0;
}

div#panels-ipe-control-container {
  position: fixed;
  top: 57px;
  right: 90px;
  left: auto;
  z-index: 1500;
  width: 300px;
}

div#panels-ipe-control-container .panels-ipe-form-container form .form-submit {
  padding-left: 10px;
  margin-left: 0;
}

#contextual-tabs,
#panels-ipe-customize-page,
div.panels-ipe-pseudobutton-container a.panels-ipe-change-layout {
  display: none;
}

div.panels-ipe-control .panels-ipe-save, div.panels-ipe-control .panels-ipe-cancel {
  background: none;
  color: #fff;
  box-shadow: none;
  font-family: Roboto, Arial, sans-serif;
  font-size: 16px;
}

div.panels-ipe-control .panels-ipe-save:hover, div.panels-ipe-control .panels-ipe-cancel:hover {
  background: none;
}

#panopoly-form-widget-preview {
  padding-bottom: 30px;
}

#edit-field-basic-text-text select {
  margin-top: 30px;
}

.oa-files-treeview {
  margin-top: 15px;
  position: relative;
}

.oa-files-treeview.oa-files-thumbnail {
  margin-top: -30px;
}

.oa-files-treeview a.btn {
  background-color: #3271b3;
  color: #fff;
  border-style: none;
}

.oa-files-treeview .oa-files-topbuttons i {
  display: none;
}

.oa-files-treeview .oa-file i {
  top: -26px;
}

.oa-files-treeview .oa-file-list-hdr {
  padding-right: 110px;
  background-color: #8B8B8B;
  color: #fff;
  height: 30px;
}

.oa-files-treeview .oa-file-list-hdr .oa-file-info {
  font-size: 14px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.oa-files-treeview .oa-file-list-hdr .name-hdr {
  right: -15px;
}

.oa-files-treeview .oa-file-list-hdr .mod-hdr {
  padding-left: 0;
  margin-right: -95px;
}

.oa-files-treeview .oa-file-list-hdr .upload-hdr {
  padding-left: 66px;
}

.oa-files-treeview .oa-file-list-hdr .size-hdr {
  right: 0;
  left: -2px;
}

.oa-files-treeview li.tree-leaf:nth-child(odd) div {
  background-color: #fff;
}

.oa-files-treeview li.tree-leaf:nth-child(even) div {
  background-color: #edf0f4;
}

.oa-files-treeview .oa-files-leaf:hover .oa-file-info, .oa-files-treeview .oa-files-leaf:hover span, .oa-files-treeview .oa-files-leaf:hover .ajax-progress {
  color: #555;
}

.oa-files-treeview .custom-file-thumb-img, .oa-files-treeview .custom-file-thumb-icon {
  border-color: #C1C1C1;
}

.oa-files-treeview .custom-file-thumb-desc {
  color: #000;
}

.intrinsic-container {
  position: relative;
  height: 0;
  overflow: hidden;
}

.intrinsic-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* 16x9 Aspect Ratio */
.intrinsic-container-16x9 {
  padding-bottom: 56.25%;
}

/* 4x3 Aspect Ratio */
.intrinsic-container-4x3 {
  padding-bottom: 75%;
}

.token-tree .expander {
  margin-left: 0 !important;
}

#modalContent h5 {
  display: none;
}

#modalContent ul {
  margin: 10px 0 0;
}

#modalContent .panels-section-column-categories .btn {
  border-width: 0 !important;
}

#modalContent .panels-categories-box .btn {
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  color: #565656;
  font-family: 'Roboto', sans-serif;
}

#modalContent .panels-categories-box .btn:hover, #modalContent .panels-categories-box .btn:focus, #modalContent .panels-categories-box .btn.active {
  background: none;
  box-shadow: none;
  color: #5d9dd2 !important;
  border-width: 0;
  outline: none;
}

#modalContent .panels-root-content-box {
  display: none;
}

#modalContent .button {
  background-color: transparent;
  color: #3271b3 !important;
  padding: 0;
  font-size: 24px;
  border-width: 0;
}

#modalContent .btn-success {
  background-color: #3271b3;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
}

#modalContent .btn-success i {
  display: none;
}

#modalContent .help-block {
  margin-top: 0;
}

#modal-content .panels-add-content-modal .panopoly-magic-preview-link {
  border-bottom-width: 0;
  font-size: 24px;
}

#modal-content .panels-add-content-modal .widget-preview {
  margin: 20px 0;
}

#modal-content .panels-add-content-modal form .form-item:first-child {
  margin-top: 0;
}

form#add-message-edit {
  width: 100%;
}

form#add-message-edit .form-item-dropdown label {
  line-height: 34px;
  margin-right: 10px;
}

.oa-navbar .panel-panel .panel-pane.pane-superfish-1 ul li:hover > a, .oa-navbar .panel-panel .panel-pane.pane-superfish-1 ul li.sfHover > a {
  background-color: #c1c1c1;
  color: #fff;
}

.oa-navbar .panel-panel .panel-pane.pane-superfish-1 ul li:hover > a i, .oa-navbar .panel-panel .panel-pane.pane-superfish-1 ul li.sfHover > a i {
  color: #fff;
}
