// Styles for cookie compliance banner
// --------------------------------------------------
@import "radix/mixins";

#sliding-popup .popup-content #popup-buttons {
  margin-left: 0px;
}

@media (min-width: $screen-xs-max) {
  #sliding-popup .popup-content #popup-buttons {
    float: left;
    margin-left: 25px;
  }
}
