// Styles for comment.
// --------------------------------------------------
@import "radix/mixins";

div.comment-form {
  margin-right: 10px;
}

form.comment-form {
  margin-bottom: 60px;

  .form-actions {
    margin: 65px 4px 0 0;

    .ajax-progress-throbber {
      margin-top: 5px;
      float: right;
      margin-right: 10px;
    }

    input[id^=cancel-ajax-comments-reply-form] {
      clear: none;
    }
  }

  label[for^=edit-comment-body] {
    display: none;
  }

  div[id^=edit-comment-body] {
    margin-left: 28%;
  }
}

.comment-form-row.SM div[id^=edit-comment-body] {
  margin-left: 83px;
}

.indented > form.comment-form {
  padding-left: 75px;
}

@media (max-width: $screen-xs-max) {
  .indented > form.comment-form {
    padding-left: 5px;
  }
}

.panel-pane.pane-node-comment-form {
  background-color: #fff;
  padding: 20px 20px 5px 20px;

  h2 {
    display: none;
  }
}

.comment-user-img {
  float: left;
}

.comment-user-img img {
  height: 55px;
  width: 55px;
  max-height: none;
  max-width: none;
  display: block;
  margin-top: 7px;
}

#comments .oa-comment {
  &.well {
    background-image: none;
    background-color: #fff;
    border-style: none;
  }

  .links {
    float: left;
    font-size: 14px;
    text-transform: capitalize;
    margin-left: -5px;
    color: #336EAA;

    a {
      color: #336EAA;
      font-size: 14px;
    }

    .ajax-progress-throbber {
      margin-right: 3px;
    }

    @media (max-width: $screen-xs-max) {
      a {
        font-size: 12px;
      }
    }
  }

  .user-info {
    margin-bottom: 10px;

    a, .oa-date {
      font-size: 16px;
    }

    a {
      color: #2C6FB6;
    }

    .oa-date {
      color: #336EAA;
      padding-left: 0;
    }
  }

  .user-picture img {
    height: 55px;
    width: 55px;
    max-height: none;
    max-width: none;
  }

  .oa-date {
    display: block;
    float: none !important;
  }

  .comment-like {
    i {
      margin-right: 3px;
    }
  }

  .comment-like-bar {
    display: none;
  }

  .like-summary {
    text-transform: none;
    font-size: 13px;
  }

  form.confirmation .ajax-progress-throbber {
    margin-right: 6px;
  }

  @media (max-width: $screen-xs-max) {
    .like-summary {
      font-size: 12px;
      line-height: 120%;
      margin-left: 5px;
      display: block;
    }
  }
}

#comments, .pane-node-comments {
  background-color: #fff;
  margin-top: 0;
  padding: 0;

  h2 {
    display: none;
  }
}

#comments .comment-reply-post-button {
  width: 200px;
}

.comment-unpublished {
  background-color: #ffffff;
}

.pane-content > .oa-comment {
  margin-top: 20px;
}

.oa-comment ~ .indented {
  margin: 20px 0 0 0;

  > .oa-comment {
    padding-left: 5px;
  }
}

#comments.SM {
  .comment-like-bar {
    display: inline;
  }

  .oa-comment ~ .indented {
    > .oa-comment {
      padding-left: 75px;
    }
  }
}

#comments.MD {
  .oa-date {
    float: right !important;
    padding-left: 5px;
  }

  .oa-comment ~ .indented {
    .indented > .oa-comment {
      padding-left: 150px;
    }
  }
}

.oa-comment-reply-body {
  font-size: 14px;
  padding: 8px 5px 0 5px;

  @media (max-width: $screen-xs-max) {
    .label {
      display: inline-block;
      margin-top: 5px;
    }
  }
}
