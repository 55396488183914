// Footer Layout
// -----------------------------------------------------------------------------
//
@import "base/variables";
@import "radix/mixins";

#auth-footer {
  text-align: center;
  margin: 20px 0;

  img {
    margin-bottom: 10px;
  }
}

body #footer {
  background-color: transparent;
  text-align: center;
  border-top-style: none;

  @include breakpoint(sm) {
    .footer-line {
      display: inline;
    }

    .footer-bullet {
      margin: 0 1px 0 3px;
    }
  }
}

@media (max-width: $screen-xs-max) {
  #footer .footer-bullet {
    display: none;
  }
}

